import React, { Component } from 'react';

import {
  FilterRequestAPI,
  SearchFilterTypes,
} from '../../../services/Filters/FilterTypes';
import { SECTIONS } from '../../../types';

import VariableSingleSelect from '../SingleSelect/VariableSingleSelect';

type SortFilterProps = {
  currentValue: string;
  id: string;
  onChange: (filterObject: FilterRequestAPI) => void;
  showLabel: boolean;
  hasActiveBorder?: boolean;
  section: SECTIONS;
};

type SortFilterState = {
  currentValue: { values: string[] };
};
type Option = {
  value: string;
  displayName: string;
};

type SortValueMapType = { [key in SECTIONS]?: Option[] } & {
  DEFAULT: Option[];
};

export const sortValues = [
  {
    value: 'bestMatch',
    displayName: 'Best Match',
  },
  {
    value: 'publishDateDesc',
    displayName: 'Most Recent',
  },
  {
    value: 'priceAsc',
    displayName: 'Price Low to High',
  },
  {
    value: 'priceDesc',
    displayName: 'Price High to Low',
  },
  {
    value: 'soldDateDesc',
    displayName: 'Most Recent',
  },
];

const sortValueMap: SortValueMapType = {
  [SECTIONS.VT_RESIDENTIAL_RENT]: [
    { value: 'publishDateDesc', displayName: 'Listed Date Most Recent' },
    { value: 'publishDateAsc', displayName: 'Listed Date Least Recent' },
    { value: 'priceDesc', displayName: 'Rental Price High to Low' },
    { value: 'priceAsc', displayName: 'Rental Price Low to High' },
  ],
  [SECTIONS.VT_COMMERCIAL]: [
    { value: 'publishDateDesc', displayName: 'Listed Date Most Recent' },
    { value: 'publishDateAsc', displayName: 'Listed Date Least Recent' },
    { value: 'priceDesc', displayName: 'Asking Sale Price High to Low' },
    { value: 'priceAsc', displayName: 'Asking Sale Price Low to High' },
  ],
  [SECTIONS.VT_PROPERTY_PRICE_REGISTER]: [
    { value: 'soldDateDesc', displayName: 'Sold Date Most Recent' },
    { value: 'soldDateAsc', displayName: 'Sold Date Least Recent' },
    { value: 'soldPriceDesc', displayName: 'Sold Price High to Low' },
    { value: 'soldPriceAsc', displayName: 'Sold Price Low to High' },
  ],
  [SECTIONS.VT_RESIDENTIAL_SALES]: [
    { value: 'firstPublishDateDesc', displayName: 'Sold Date Most Recent' },
    { value: 'firstPublishDateAsc', displayName: 'Sold Date Least Recent' },
    { value: 'soldPriceDesc', displayName: 'Sold Price High to Low' },
    { value: 'soldPriceAsc', displayName: 'Sold Price Low to High' },
  ],
  [SECTIONS.SOLD]: [
    { value: 'bestMatch', displayName: 'Best Match' },
    { value: 'soldDateDesc', displayName: 'Most Recent' },
    { value: 'priceAsc', displayName: 'Asking Price Low to High' },
    { value: 'priceDesc', displayName: 'Asking Price High to Low' },
  ],
  DEFAULT: [
    { value: 'bestMatch', displayName: 'Best Match' },
    { value: 'publishDateDesc', displayName: 'Most Recent' },
    { value: 'priceAsc', displayName: 'Price Low to High' },
    { value: 'priceDesc', displayName: 'Price High to Low' },
  ],
};

class SortFilter extends Component<SortFilterProps, SortFilterState> {
  state = this.getCurrentValue();

  renderDefaultCurrentValue(section: keyof SortValueMapType) {
    return sortValueMap[section]?.[0].value || 'bestMatch';
  }

  getCurrentValue() {
    const currentValueString = this.props.currentValue
      ? this.props.currentValue
      : this.renderDefaultCurrentValue(this.props.section);
    return { currentValue: { values: [currentValueString] } };
  }

  componentDidUpdate(prevProps: SortFilterProps) {
    if (this.props.currentValue !== prevProps.currentValue) {
      this.setState(this.getCurrentValue());
    }
  }

  renderSortValues() {
    const section = this.props.section;

    return sortValueMap[section as SECTIONS] || sortValueMap.DEFAULT;
  }

  render() {
    const { id, onChange, showLabel, hasActiveBorder } = this.props;
    const { currentValue } = this.state;
    return (
      <VariableSingleSelect
        id={id}
        showLabel={showLabel}
        filterType={{ name: SearchFilterTypes.ListSingleSelect }}
        name="sort"
        searchQueryGroup="sort"
        displayName="Sort"
        currentValue={currentValue}
        onChange={onChange}
        hasActiveBorder={hasActiveBorder}
        values={this.renderSortValues()}
      />
    );
  }
}

export default SortFilter;
