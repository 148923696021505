import React, { Component, ComponentType } from 'react';

import { SearchIcon } from '@dsch/icons';
import dynamic from 'next/dynamic';
import AutosizeInput from 'react-input-autosize';
import styled, { css } from 'styled-components';

import {
  NewButton,
  NewButtonTypes,
  NewButtonSizes,
} from '../../components/Toolkit/NewButton/NewButton';
import { getRootNode, safeGet } from '../../helpers/utils';

import { MultiInputTextSelect } from './MultiInputTextSelect';
import { DesktopRadiusFilter, MobileRadiusFilter } from './RadiusFilter';
import TagValue from './TagValue';
import { AreaTag, LocationInputBoxOptions, SECTIONS } from '../../types';

const CountyAreaFilterDropdown: ComponentType<any> = dynamic(
  () =>
    import(
      /*webpackChunkName: "CountyAreaFilter-CHUNK"*/ '../../components/MultiInputTextSelect/CountyAreaFilterDropdown'
    ).then((module) => module.CountyAreaFilterDropdown),
  { ssr: false },
);

const MINIMUM_CHARS_BEFORE_SEARCH = 3;

type FilterValueType = { location: string[]; radius: string };

type CountyAreaProps = {
  id: string;
  name: string;
  alternativeStyle?: boolean;
  values: any[];
  onChange: (filter: any) => void;
  /**
   * Pass in displayValues for current value, County-Area filter will convert to AreaTags
   */
  currentValue?: FilterValueType;
  variant?: string;
  items: AreaTag[];
  onInputChange?: (input: string) => void;
  onBlur?: () => void;
  onFocus?: () => void;
  cleanup?: () => void;
  options?: LocationInputBoxOptions;
  parentEl?: HTMLElement;
  isLoading: boolean;
};

type CountyAreaState = {
  inputFocus: boolean;
  errorMessage: string;
  selectedItems: AreaTag[];
  items: AreaTag[];
  radius: string;
  placeHolderText: string;
};

const SearchIconStyled = styled(SearchIcon)`
  && {
    display: none;
    width: 24px;
    height: 24px;
    color: #777777;
    @media only screen and (min-width: 704px) {
      display: inline;
      position: absolute;
      left: 28px;
      top: 20px;
    }
  }
`;

export const TagStyled = styled(TagValue)`
  & {
    min-height: 30px;
    @media only screen and (min-width: 704px) {
      margin-top: 0;
      min-height: 36px;
      line-height: 26px;
    }
    margin: 13px 4px 4px 5px;

    &:not(:first-child) {
      margin: 5px 8px 4px 5px;
    }
  }
`;

const topCornersRounded = css`
  border-radius: 0;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
`;

const TextCutter = styled.div`
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  @media only screen and (min-width: 704px) {
    padding-left: 24px;
  }
`;

const focusedStyle = css`
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.15);
  flex-wrap: wrap;
  display: flex;
  background-color: #ffffff;

  @media only screen and (min-width: 704px) {
    box-shadow: none;
    border: 1px solid #e7e4e4;
    padding-top: 4px;
  }
`;

const unFocusedStyle = css`
  height: 50px;
  line-height: 49px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  align-items: center;

  @media only screen and (min-width: 704px) {
    height: 65px;
    line-height: 46px;
    border: 1px solid #e7e4e4;
  }

  ${TextCutter} {
    padding-right: 28px;
    @media only screen and (min-width: 704px) {
      padding-right: 132px;
    }
  }
`;

const AutosizeInputStyled = styled(AutosizeInput)`
  position: relative;
  margin-right: 4px;
  border-radius: 5px;
  font-weight: 400;
  line-height: 38px;
  opacity: 0.9;
  font-size: 16px;
  min-width: 198px;

  @media only screen and (min-width: 640px) {
    font-size: 14px;
    line-height: 56px;
    margin-left: 0;
  }

  input {
    color: #333333;
    border: none;
    cursor: text;
    outline: none;
    font-size: 16px;
    caret-color: #777777;
    height: 100%;
    background: transparent;

    &::-ms-clear {
      display: none;
    }
    @media only screen and (min-width: 640px) {
      font-size: 14px;
      height: 18px;
      margin-top: 0px;
    }
  }
`;

const InputValueWrapper = styled.div`
  font-size: 16px;
  background-color: #f4f4f4;
  border-radius: 4px;
  padding-left: 20px;
  padding-right: 11px;
  padding-top: 8px;
  padding-bottom: 8px;
  position: relative;
  cursor: text;
  display: flex;

  ${({ isOpen }: { isOpen: boolean }) =>
    isOpen
      ? `
      ${topCornersRounded}
      background-color: #ffffff;
    `
      : 'border-radius: 4px;'};

  ${({ inputFocus }: any) => (inputFocus ? focusedStyle : unFocusedStyle)};

  ${({ numberOfItemsSelected, inputFocus, radius }) => {
    const addLocationsText =
      numberOfItemsSelected === 1 ? '+ Add locations' : '';

    if (!inputFocus && (radius || addLocationsText)) {
      return `
      // When one item is selected and the input is not in focus show extra text
      // and add extra padding
      ${TextCutter} {
        padding-right: 132px;
        @media only screen and (min-width: 704px) {
          padding-right: 246px;
        }
      }
      
      &:before {
        content: "${radius ? `+ ${radius / 1000}km` : addLocationsText}";
        position: absolute;
        right: 20px;
        color: ${radius ? '#000000' : '#999999'};
        font-size: 14px;
        font-weight: 600;
        top: 0;
        bottom: 0;
        line-height: 1;
        display: flex;
        align-items: center;
        
        @media only screen and (min-width: 640px) {
          right: 140px;
          color: #999999;
          content: "${addLocationsText ? addLocationsText : ''}"
          ${!addLocationsText ? 'display: none;' : ''}
        }
      }
    `;
    }
    return `
    &:before {
      display: none;
    }
  `;
  }};

  ${({ numberOfItemsSelected, inputFocus }) =>
    numberOfItemsSelected > 0 && !inputFocus
      ? `
      ${TextCutter} {
        text-overflow: ellipsis;
      }
    `
      : ''}

  ${({ numberOfItemsSelected }) =>
    numberOfItemsSelected > 0
      ? `padding-top: 4x !important;
        padding-left: 8px;
        `
      : ``};

  ${AutosizeInputStyled} {
    @media only screen and (min-width: 640px) {
    }
  }

  @media only screen and (min-width: 640px) {
    font-size: 14px;
    padding-right: 21px;
    padding-top: 4px;
    padding-bottom: 3px;
  }

  @media only screen and (min-width: 704px) {
    background-color: #ffffff;
    padding-left: 30px;
  }

  @media print {
    font-size: 12px;
  }
`;

const MultiInputTextSelectWrapper = styled.div`
  position: relative;
  display: inline-flex;
  width: ${({ alternativeStyle }: { alternativeStyle: any }) =>
    alternativeStyle ? '310px' : '100%'};

  ${({ shouldShowOnDesktop }: any) =>
    shouldShowOnDesktop
      ? ''
      : '@media only screen and (min-width: 900px) { display: none;}'}
`;

const FocusableArea = styled.div`
  width: 100%;
  z-index: 500;
  left: 0;
  top: 0;
`;

const PlainInputText = styled.span`
  font-weight: 600;
  padding-left: ${({ children }) => (children ? '9px' : '0')};
`;

const RadiusFilterDesktopWrapper = styled.div`
  position: absolute;
  padding: 0 18px 0 10px;
  z-index: 500;
  right: 8px;
  border-left: solid #dddddd 1px;
`;

const DoneButtonContainer = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  align-self: flex-end;
  justify-self: flex-end;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 16px;
`;

export class CountyAreaFilter extends Component<
  CountyAreaProps,
  CountyAreaState
> {
  constructor(props: CountyAreaProps) {
    super(props);
    const { radius, selectedItems } = this.getInitialValue();
    this.state = {
      radius,
      selectedItems,
      items: this.getFilteredItemsFromSelected(
        this.props.items || [],
        selectedItems,
      ),
      inputFocus: false,
      errorMessage: '',
      placeHolderText: this.getPlaceholderText(),
    };
  }

  getPlaceholderText = () => {
    return (
      safeGet(this.props, ['options', 'placeholderText'], false) ||
      'County, City, Town or Area'
    );
  };

  getFilteredItemsFromSelected = (
    items: AreaTag[],
    selectedItems: AreaTag[],
  ) => {
    const filteredItems = Array.isArray(items) ? [...items] : [];
    selectedItems.forEach((selectedItem) => {
      const indexToRemove = filteredItems.findIndex(
        (item) => item.id === selectedItem.id,
      );

      if (indexToRemove > -1) {
        filteredItems.splice(indexToRemove, 1);
      }
    });
    return filteredItems;
  };

  componentDidUpdate(prevProps: CountyAreaProps) {
    if (this.props.options !== prevProps.options) {
      this.parentEl = safeGet(this.props, ['options', 'parentRef']);
      if (this.state.inputFocus) {
        this.mobileShift();
      }
    }

    if (this.props.items !== prevProps.items) {
      this.setState({
        items: this.getFilteredItemsFromSelected(
          this.props.items,
          this.state.selectedItems,
        ),
      });
    }

    if (this.props.currentValue !== prevProps.currentValue) {
      const { radius, selectedItems } = this.getInitialValue();
      this.setState({
        radius,
        selectedItems,
        items: this.getFilteredItemsFromSelected(
          this.props.items || [],
          selectedItems,
        ),
      });
    }
  }

  componentWillUnmount() {
    this.props.cleanup && this.props.cleanup();
  }

  inputRef: React.RefObject<HTMLInputElement>;
  menuRef: React.RefObject<HTMLDivElement> = React.createRef();
  cutterRef: React.RefObject<HTMLDivElement> = React.createRef();
  wrapperRef: HTMLDivElement;
  parentEl: HTMLElement;

  getInitialValue() {
    const { currentValue = {}, values } = this.props;
    let initialTags: any = [];
    const { location = [], radius = '' } = currentValue as FilterValueType;
    const locations = location[0] === 'mapArea' ? ['mapArea'] : location;

    locations.forEach((locationDisplayValue) => {
      if (locationDisplayValue === 'mapArea') {
        initialTags = [
          {
            id: 'mapArea',
            displayName: 'Map Area',
            displayValue: 'mapArea',
          },
        ];
      }
      const currentTag = values.find(
        (item: AreaTag) => item.displayValue === locationDisplayValue,
      );

      if (currentTag) {
        initialTags = initialTags.concat(currentTag);
      }
    });

    return { radius, selectedItems: initialTags };
  }

  componentDidMount() {
    this.parentEl = safeGet(this.props, ['options', 'parentRef', 'current']);

    if (safeGet(this.props, ['options', 'initWithFocus'], false)) {
      this.setFocus();
      const focusInput = safeGet(this.inputRef, ['current', 'focus']);
      if (focusInput) {
        this.inputRef!.current!.focus();
      }
    }
  }

  setRef = (element: any) => {
    this.wrapperRef = element;
  };

  setFocus = (event?: any) => {
    if (event) {
      event.preventDefault();
    }
    this.props.onFocus && this.props.onFocus();

    this.setState({ inputFocus: true, placeHolderText: '' }, () => {
      document.addEventListener('click', this.unsetFocus, { capture: true });
      this.mobileShift();
    });
  };

  unsetFocus = (event?: any) => {
    if (event) {
      if (!this.state.inputFocus || !this.wrapperRef) return;
      const rootNode = getRootNode(event.target);
      const dataTestId =
        rootNode.getAttribute && rootNode.getAttribute('data-testid');

      if (
        (this.wrapperRef as any).contains(event.target) ||
        dataTestId === 'menu' ||
        dataTestId === 'tag' ||
        dataTestId === 'item' ||
        dataTestId === 'cutter'
      ) {
        return;
      }
    }
    this.props.onBlur && this.props.onBlur();
    this.setState(
      { inputFocus: false, placeHolderText: this.getPlaceholderText() },
      () => {
        document.removeEventListener('click', this.unsetFocus);
      },
    );
  };

  onSelectionChange = (selectedItems: AreaTag[]) => {
    selectedItems = selectedItems.filter(
      (item) => item.displayValue !== 'mapArea',
    );

    this.setState({ selectedItems }, () => {
      const storedShapeIds = selectedItems.map((item) => item.id);

      this.props.onChange({
        storedShapeIds,
        filterType: 'CountyArea',
        searchQueryGroup: 'geoFilter',
        geoSearchType: 'STORED_SHAPES',
        currentValue: {
          location: selectedItems.map((item) => item.displayValue),
          radius: selectedItems.length === 1 ? this.state.radius : '',
        },
        name: this.props.name,
      });
    });
  };

  mobileShift() {
    if (screen.width <= 525) {
      const topPadding = 10;
      const offset = this.parentEl
        ? this.parentEl.scrollTop
        : window.pageYOffset || document.documentElement.scrollTop;
      const elementPos = this.wrapperRef.getBoundingClientRect().top;

      (this.parentEl || window).scrollTo({
        top: offset + elementPos - topPadding,
        behavior: 'smooth',
      });
    }
  }

  render() {
    const { isLoading } = this.props;

    const selectedSection: SECTIONS = safeGet(this.props, [
      'options',
      'selectedSection',
    ]);

    const currentPath: string = safeGet(this.props, ['options', 'currentPath']);

    const isDisabledCountSection =
      (selectedSection && selectedSection === SECTIONS.RECENT) ||
      selectedSection === SECTIONS.SOLD;

    const shouldShowAreaCounts =
      safeGet(this.props, ['options', 'shouldShowAreaCounts'], false) &&
      !isDisabledCountSection &&
      currentPath !== '/colleges';

    return (
      <>
        <MultiInputTextSelectWrapper
          alternativeStyle={this.props.alternativeStyle}
          ref={this.setRef}
          shouldShowOnDesktop={safeGet(
            this.props,
            ['options', 'shouldShowOnDesktop'],
            true,
          )}
        >
          <MultiInputTextSelect
            id={this.props.id}
            items={this.state.items}
            placeHolderText={this.state.placeHolderText}
            onSelectionChange={this.onSelectionChange}
            currentValue={this.state.selectedItems}
            minChars={3}
            onInputChange={(message: string) =>
              this.props.onInputChange && this.props.onInputChange(message)
            }
            cleanup={() => this.props.cleanup && this.props.cleanup()}
          >
            {({
              getRootProps,
              getAutoInputProps,
              getItemProps,
              selectedItems = [],
              highlightedIndex,
              getMenuProps,
              getInputWrapperProps,
              getTagProps,
              items = [],
              inputValue = [],
            }: any) => {
              this.inputRef = getAutoInputProps().ref;
              const isBelowTextLimit =
                inputValue.length < MINIMUM_CHARS_BEFORE_SEARCH;
              const hasMatches = !isBelowTextLimit && items.length > 0;
              const hasSelectedItems = selectedItems.length > 0;

              const showNoMatchText =
                !isBelowTextLimit && !hasMatches && !this.state.errorMessage;

              const showAddLocation = isBelowTextLimit && !hasSelectedItems;

              const shouldShowMenu =
                hasMatches || showNoMatchText || showAddLocation;

              const numberOfItemsSelected = this.state.selectedItems.length;

              return (
                <FocusableArea
                  {...getRootProps({
                    refKey: 'county-area',
                    onClick: this.setFocus,
                  })}
                >
                  <InputValueWrapper
                    {...getInputWrapperProps({
                      numberOfItemsSelected,
                      inputFocus: this.state.inputFocus,
                      onClick: this.setFocus,
                      isOpen: this.state.inputFocus && shouldShowMenu,
                      radius: this.state.radius,
                    })}
                  >
                    <TextCutter onClick={this.setFocus} className="text-cutter">
                      <SearchIconStyled />
                      {this.state.inputFocus ? (
                        selectedItems.map((tag: AreaTag) => (
                          <TagStyled
                            {...getTagProps({ 'data-testid': 'tag' })}
                            key={tag.id}
                          >
                            {tag}
                          </TagStyled>
                        ))
                      ) : (
                        <PlainInputText onClick={this.setFocus}>
                          {selectedItems
                            .map((tag: AreaTag) => tag.displayName)
                            .join(', ')}
                        </PlainInputText>
                      )}
                      <AutosizeInputStyled
                        data-hj-whitelist
                        {...getAutoInputProps({
                          'data-testid': 'county-area-filter',
                        })}
                      />
                    </TextCutter>
                    {this.state.inputFocus && (
                      <DoneButtonContainer>
                        <NewButton
                          data-test-id="homepage-search-btn"
                          buttonText="DONE"
                          data-testid="filter-done-button"
                          buttonType={NewButtonTypes.TERTIARY}
                          buttonSize={NewButtonSizes.SMALL}
                          onClick={(event: any) => {
                            event.stopPropagation();
                            this.unsetFocus();
                          }}
                        />
                      </DoneButtonContainer>
                    )}
                  </InputValueWrapper>
                  <div ref={this.menuRef}>
                    {this.state.inputFocus && (
                      <CountyAreaFilterDropdown
                        showAddLocation={showAddLocation}
                        isLoading={isLoading}
                        hasSelectedItems={hasSelectedItems}
                        showNoMatchText={showNoMatchText}
                        isBelowTextLimit={isBelowTextLimit}
                        getMenuProps={getMenuProps}
                        items={items}
                        getItemProps={getItemProps}
                        highlightedIndex={highlightedIndex}
                        inputValue={inputValue}
                        shouldShowAreaCounts={shouldShowAreaCounts}
                        selectedSection={selectedSection}
                      />
                    )}
                  </div>
                </FocusableArea>
              );
            }}
          </MultiInputTextSelect>

          {Boolean(
            this.props.alternativeStyle &&
              this.props.variant === 'DAFT_DEFAULT',
          ) && (
            <RadiusFilterDesktopWrapper className="small-radius-filter">
              <DesktopRadiusFilter
                onChange={this.props.onChange}
                locationFilterCurrentValue={this.props.currentValue}
                inputFocus={this.state.inputFocus}
              />
            </RadiusFilterDesktopWrapper>
          )}
        </MultiInputTextSelectWrapper>
        {Boolean(
          !this.props.alternativeStyle &&
            this.state.selectedItems.length === 1 &&
            this.state.selectedItems[0].id !== 'mapArea' &&
            this.props.variant === 'DAFT_DEFAULT',
        ) && (
          <MobileRadiusFilter
            onChange={this.props.onChange}
            locationFilterCurrentValue={this.props.currentValue}
            inputFocus={this.state.inputFocus}
          />
        )}
      </>
    );
  }
}
